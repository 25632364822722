
<template>
  <Layout>
    <div class="row">
      <div class="openstreetmap" :style="
        'height:' + this.screenHeight + 'px;position:sticky'
      ">
        <l-map :zoom="17" :center="[-2.576146, 106.132385]" :options="{ scrollWheelZoom: false }">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker v-for="(location, index) in locations" :lat-lng="location.position" v-bind:key="index" @click="
            getDetail(location.id);
                                    ">
            <l-popup>
              <div>{{ location.name }}</div>
              <div></div>
              <!-- <div style="margin-top:10px" class="justify-content-center"><b-button variant="info" @click="getEfektifitas(location.id,location.name)"><span style="font-size:12px">Lihat Data</span></b-button></div> -->
            </l-popup>
          </l-marker>

          <l-control-layers></l-control-layers>
          <l-control :position="'topright'">
            
            <div v-if="this.$device.mobile == false" class="card" style="width: 25rem">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="card-title">Kelompok Tani</h5>
                  </div>
                  <div class="col-md-6">
                    <button style="float: right" v-on:click="isHidden = !isHidden">
                      <i v-if="isHidden" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>
                <div v-if="!isHidden">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="dataketan" :fields="fields" responsive="sm" :per-page="perPage"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn">
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl1" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$device.mobile == false" class="card" style="width: 25rem">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="card-title">Lokasi Lahan</h5>
                  </div>
                  <div class="col-md-6">
                    <button style="float: right" v-on:click="isHiddenlh = !isHiddenlh">
                      <i v-if="isHiddenlh" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenlh">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter2" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datalola" :fields="fieldslola" responsive="sm" :per-page="perPage2"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter2"
                      :filter-included-fields="filterOn">
                      <template #cell(aksi)="data">
                        <b-button variant="info" style="margin-right: 5px" @click="getDetail(data.item.id)"><i
                            class="fas fa-eye"></i></b-button>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl2" :per-page="perPage2"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </l-control>

          <l-control :position="'topleft'" class="example-custom-control">
            <div v-if="this.$device.mobile == false" class="card" style="width: 25rem">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h5 class="card-title">Potensi Panen Minggu ini</h5>
                  </div>
                  <div class="col-md-4">
                    <button style="float: right" v-on:click="isHiddenpm = !isHiddenpm">
                      <i v-if="isHiddenpm" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenpm">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter3" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datapm" :fields="fieldspm" responsive="sm" :per-page="perPage3"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter3"
                      :filter-included-fields="filterOn">
                      <template #cell(aksi)="data">
                        <b-button variant="info" style="margin-right: 5px" v-b-modal.modal-detail
                          @click="getperuser(data.item.id)"><i class="fas fa-eye"></i></b-button>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl3" :per-page="perPage3"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$device.mobile == false" class="card" style="width: 25rem">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h5 class="card-title">Potensi Panen Bulan ini</h5>
                  </div>
                  <div class="col-md-4">
                    <button style="float: right" v-on:click="isHiddenpb = !isHiddenpb">
                      <i v-if="isHiddenpb" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenpb">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter4" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datapb" :fields="fieldspb" responsive="sm" :per-page="perPage4"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter4"
                      :filter-included-fields="filterOn">
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl4" :per-page="perPage4"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </l-control>

          <l-control :position="'bottomleft'" style="width: 25rem">
            <div class="row">
              <div class="col-md-2">
                <img :src="this.cuaca_img" class="rounded mx-auto d-block" width="60px">
              </div>
              <div class="col-md-6">
                <h5 style="color:#000"><strong>{{ this.cuaca }}</strong></h5>
                <span style="color:#000">{{ this.suhu }}</span>
                <br>
                <span style="color:#000">{{ 'Curah Hujan: ' + this.curah_hujan }}</span>
                <br>
                <span style="color:#000">{{ 'Kecepatan Angin: ' + this.kecepatan_angin }}</span>
              </div>
            </div>
          </l-control>
        </l-map>
      </div>



    </div>
    <!-- end row -->

    <div class="row mt-4">

      <div v-if="this.$device.mobile" class="card col-md-12" >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="card-title">Kelompok Tani</h5>
                  </div>
                  <div class="col-md-6">
                    <button style="float: right" v-on:click="isHidden = !isHidden">
                      <i v-if="isHidden" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>
                <div v-if="!isHidden">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="dataketan" :fields="fields" responsive="sm" :per-page="perPage"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn">
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl1" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$device.mobile" class="card col-md-12">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="card-title">Lokasi Lahan</h5>
                  </div>
                  <div class="col-md-6">
                    <button style="float: right" v-on:click="isHiddenlh = !isHiddenlh">
                      <i v-if="isHiddenlh" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenlh">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter2" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datalola" :fields="fieldslola" responsive="sm" :per-page="perPage2"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter2"
                      :filter-included-fields="filterOn">
                      <template #cell(aksi)="data">
                        <b-button variant="info" style="margin-right: 5px" @click="getDetail(data.item.id)"><i
                            class="fas fa-eye"></i></b-button>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl2" :per-page="perPage2"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$device.mobile" class="card col-md-12">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h5 class="card-title">Potensi Panen Minggu ini</h5>
                  </div>
                  <div class="col-md-4">
                    <button style="float: right" v-on:click="isHiddenpm = !isHiddenpm">
                      <i v-if="isHiddenpm" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenpm">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter3" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datapm" :fields="fieldspm" responsive="sm" :per-page="perPage3"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter3"
                      :filter-included-fields="filterOn">
                      <template #cell(aksi)="data">
                        <b-button variant="info" style="margin-right: 5px" v-b-modal.modal-detail
                          @click="getperuser(data.item.id)"><i class="fas fa-eye"></i></b-button>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl3" :per-page="perPage3"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.$device.mobile" class="card col-md-12">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <h5 class="card-title">Potensi Panen Bulan ini</h5>
                  </div>
                  <div class="col-md-4">
                    <button style="float: right" v-on:click="isHiddenpb = !isHiddenpb">
                      <i v-if="isHiddenpb" class="fas fa-angle-down"></i><i v-else class="fas fa-angle-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="!isHiddenpb">
                  <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6"></div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center">
                          <b-form-input v-model="filter4" type="search" placeholder="Search..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables">
                    <b-table :items="datapb" :fields="fieldspb" responsive="sm" :per-page="perPage4"
                      :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter4"
                      :filter-included-fields="filterOn">
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rowstbl4" :per-page="perPage4"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    </div>


    <b-modal id="modal-detail" scrollable ok-title="Tutup" ok-variant="danger" cancel-variant="secondary" ok-only
      size="lg" hide-header hide-footer centered>
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4">Kode Lokasi</div>
            <div class="col-md-8">: {{ dataDetail[0].kode_lokasi }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Kelompok Tani</div>
            <div class="col-md-8">: {{ dataDetail[0].kode_kelompok_tani }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Komoditas</div>
            <div class="col-md-8">: {{ dataDetail[0].komoditas }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Varietas</div>
            <div class="col-md-8">: {{ dataDetail[0].varietas }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Metode Tanam</div>
            <div class="col-md-5">: {{ dataDetail[0].cara_tanam }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Jarak Tanam</div>
            <div class="col-md-8">: {{ dataDetail[0].jarak_tanaman }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Jarak Antar Baris</div>
            <div class="col-md-8">: {{ dataDetail[0].jarak_antar_baris }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Jarak Antar Legowo</div>
            <div class="col-md-8">: {{ dataDetail[0].jarak_antar_legowo }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Jumlah Tanam</div>
            <div class="col-md-8">: {{ dataDetail[0].jumlah_tanaman }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Waktu Tanam</div>
            <div class="col-md-8">: {{ format_date(dataDetail[0].waktu_tanam) }}</div>
          </div>
          <div class="row">
            <div class="col-md-4">Prediksi Panen</div>
            <div class="col-md-8">: {{ format_date(dataDetail[0].prediksi_panen) }}</div>
          </div>
        </div>
        <div class="col-md-3 content-justify-end">
          <div class="row">
            <div class="col-md-12">
              <b-button style="width: 140px" variant="success" v-b-modal.modal-perkembangan @click="
                getPerkembangan(dataDetail[0].id_lokasi),
                (idlokasi = dataDetail[0].id_lokasi)
              "><i class="fas fa-chart-line"></i> Perkembangan</b-button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <b-button style="width: 140px" variant="primary"
                @click="getPemeliharaan(dataDetail[0].id_lokasi);getpenanaman(dataDetail[0].id_lokasi)"><i class="fas fa-tractor"></i> Pemeliharaan</b-button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <b-button style="width: 140px" variant="danger" @click="$bvModal.hide('modal-detail')"><i
                  class="fas fa-window-close"></i> Tutup</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-perkembangan" scrollable ok-title="Tutup" ok-variant="danger" cancel-variant="secondary"
      header-close-variant="secondary" ok-only size="xl" :title="'Perkembangan Tanaman ' + dataDetail[0].kode_lokasi">
      <div class="row">
        <div class="col-md-6">
          <div id="chart">
            <apexchart type="line" height="400" :options="chartOptions" :series="series" @click="clickHandler">
            </apexchart>
          </div>
        </div>

        <div class="col-md-6">
          <div id="chart">
            <apexchart type="line" height="400" :options="chartOptionsJumlahDaun" :series="seriesJD"
              @click="clickHandler"></apexchart>
          </div>
        </div>
      </div>
    </b-modal>



    <b-modal id="modalgambar" hide-footer size="md">
      <div v-if="gambar != null" class="d-block text-center">
        <b-img thumbnail fluid style="width: 100%" :src="gambar" />
      </div>
      <div v-else class="d-block text-center">
        <img src="@/assets/images/error.png" style="width: 100%" />
        <p>Tidak ada gambar</p>
      </div>
    </b-modal>


    <!-- Modal Pemeliharaan -->

    <b-modal id="modal-pemeliharaan" scrollable title="Pemeliharaan Tanaman" cancel-title="Close" ok-title="Accept"
      cancel-variant="outline-secondary" size="fullscreen">
      <ValidationObserver ref="form">
        <form ref="form" @submit.stop.prevent="tambahdata">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Lokasi" label-for="name" class="mb-3">
                    <v-select style="color:#2E3648;" :options="datalokasi" @input="getpenanaman(form.id_lokasi)"
                      v-model="form.id_lokasi" :reduce="(datalokasi) => datalokasi.id" label="kode_lokasi" disabled />

                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Tanggal Penanaman" label-for="name" class="mb-3">
                    <v-select style="color:#2E3648;" :options="datapenanaman" v-model="form.id_penanaman"
                      :reduce="(datapenanaman) => datapenanaman.id" label="waktu_tanam" disabled />

                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan I (<span style="color:red">7HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemupukan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.p1_tanggal_pemupukan">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Pupuk</th>
                              <th>Dosis</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_nama_pupuk_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_dosis_pupuk_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_nama_pupuk_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_dosis_pupuk_2" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_nama_pupuk_3" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p1_dosis_pupuk_3" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Penyulaman (<span style="color:red">7HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tanggal Penyulaman" label-for="tanggal pesanan" class="mb-3">
                        <input disabled type="date" class="form-control" v-model="form.penyulaman_tanggal">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Jumlah Penyulaman" label-for="tanggal pesanan" class="mb-3">
                        <b-form-input disabled id="name" v-model="form.penyulaman_jumlah" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Gulma (<span style="color:red">10HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Herbisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.pg_tanggal_herbisida">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Herbisida</th>
                              <th>Dosis Herbisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pg_nama_herbisida_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pg_dosis_herbisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pg_nama_herbisida_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pg_dosis_herbisida_2" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Monitoring & Pengendalian OPT (<span style="color:red">15HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungisida/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.opt_tanggal_fungisida">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungisida/Insektisida</th>
                              <th>Dosis Fungisida/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.opt_nama_fungisida_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.opt_dosis_fungisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.opt_nama_fungisida_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.opt_dosis_fungisida_2" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan II (<span style="color:red">25HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemupukan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.p2_tanggal_pemupukan">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Pupuk</th>
                              <th>Dosis Pupuk</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_nama_pupuk_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_dosis_pupuk_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_nama_pupuk_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_dosis_pupuk_2" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_nama_pupuk_3" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.p2_dosis_pupuk_3" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Pembumbunan (<span style="color:red">25HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tanggal Pembubunan" label-for="tanggal pesanan" class="mb-3">
                        <input disabled type="date" class="form-control" v-model="form.pa_tanggal">

                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="4">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan MKP I (<span style="color:red">30HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tanggal Pembubunan" label-for="tanggal pesanan" class="mb-3">
                        <input disabled type="date" class="form-control" v-model="form.mkp1_tanggal_pemupukan">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Dosis MKP" label-for="tanggal pesanan" class="mb-3">
                        <b-form-input disabled id="name" v-model="form.mkp1_dosis_pupuk" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>


            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Cendawan dan Hama I (<span style="color:red">35HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.pch35_tanggal">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungi/Insektisida</th>
                              <th>Dosis Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch35_nama_fungi_insektisida_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch35_dosis_fungi_insektisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch35_nama_fungi_insektisida_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch35_dosis_fungi_insektisida_2" />
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pemupukan MKP II (<span style="color:red">45HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Tgl Pemupukan" label-for="tanggal pesanan" class="mb-3">
                        <input disabled type="date" class="form-control" v-model="form.mkp2_tanggal_pemupukan">
                      </b-form-group>

                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Dosis MKP" label-for="tanggal pesanan" class="mb-3">
                        <b-form-input disabled id="name" v-model="form.mkp2_dosis_pupuk" />
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card>
                <h5 slot="header" class="mb-0">Pengendalian Cendawan dan Hama II (<span style="color:red">50HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="4">
                      <div class="table-responsive mb-0">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th>Tanggal Pemberian Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">
                                <input disabled type="date" class="form-control" v-model="form.pch50_tanggal">
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </b-col>

                    <b-col md="8">
                      <div class="table-responsive mb-0">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nama Fungi/Insektisida</th>
                              <th>Dosis Fungi/Insektisida</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch50_nama_fungi_insektisida_1" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch50_dosis_fungi_insektisida_1" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch50_nama_fungi_insektisida_2" />
                              </td>
                              <td>
                                <b-form-input disabled id="name" v-model="form.pch50_dosis_fungi_insektisida_2" />
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Pruning (<span style="color:red">80HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input disabled type="date" class="form-control" v-model="form.pr_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Chopping (<span style="color:red">90HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input disabled type="date" class="form-control" v-model="form.ch_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col md="2">
              <b-card>
                <h5 slot="header" class="mb-0">Panen Pipil (<span style="color:red;font-size: 12px;">100-120HST</span>)</h5>
                <b-card-text>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Tgl Pelaksanaan" label-for="tanggal pesanan" class="mb-3">
                        <ValidationProvider name="Tanggal Pembubunan" rules="required" v-slot="{ classes, errors }">
                          <input disabled type="date" class="form-control" v-model="form.pp_tanggal">
                          <span :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-form-group>

                    </b-col>

                  </b-row>
                </b-card-text>
              </b-card>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" style="float:right" @click="$bvModal.hide('modal-pemeliharaan')">
            <i class="fas fa-xmark"></i>

            Tutup
          </b-button>
        </div>
      </template>
    </b-modal>
  </Layout>
</template>

<script>
import Vue from 'vue';
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { LMap, LTileLayer, LControl, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import axios from "axios";
import Swal from "sweetalert2";

import VueApexCharts from "vue-apexcharts";

import moment from 'moment';
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import device from "vue-device-detector"
Vue.use(device)

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

let width = screen.width;
let height = screen.height - 200;

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    LMap,
    LTileLayer,
    LControl,
    apexchart: VueApexCharts,
    LMarker,
    LPopup,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      baseapi: localStorage.getItem("baseapi"),
      screenWidth: width,
      screenHeight: height,
      idlokasi: null,
      series: [],
      seriesJD: [],
      image1: "https://picsum.photos/125/125/?image=58",
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          foreColor: '#373d3f'
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [3, 3],
          curve: "smooth",
        },
        title: {
          text: "Perkembangan Tinggi Tanaman",
          align: "left",
          style: {
            fontSize: "14px",
            color: "#fff",
          },
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.2,
          },
          borderColor: "#f1f3fa",
        },
        markers: {
          style: "inverted",
          size: 6,
        },
        xaxis: {
          categories: ["M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8"],
          title: {
            text: "Minggu",
          },
        },
        yaxis: {
          title: {
            text: "Tinggi Tanaman",
          },
        },
        legend: {
          fontSize: '36px',
          labels: {
            useSeriesColors: true,
            fontSize: 34
          },
          style: {
            fontSize: 36
          }
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              legend: {
                fontSize: '36px',
                show: true,
              },
            },
          },
        ],
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50" style="color:black"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
              } CM</span></div>`;
          },
        },
      },
      chartOptionsJumlahDaun: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [3, 3],
          curve: "smooth",
        },
        title: {
          text: "Perkembangan Jumlah Daun",
          align: "left",
          style: {
            fontSize: "14px",
            color: "#fff",
          },
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.2,
          },
          borderColor: "#f1f3fa",
        },
        markers: {
          style: "inverted",
          size: 6,
        },
        xaxis: {
          categories: ["M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8"],
          title: {
            text: "Minggu",
          },
        },
        yaxis: {
          title: {
            text: "Jumlah Daun",
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50" style="color:black"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
              } Daun</span></div>`;
          },
        },
      },
      zoom: 12,
      scrollWheelZoom: false,
      url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      id: "mapbox/streets-v11",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [],
      gambar: null,
      isHidden: false,
      isHiddenlh: false,
      isHiddenpm: false,
      isHiddenpb: false,
      dataketan: [],
      datalola: [],
      datapm: [],
      datapb: [],
      dataDetail: [
        {
          kode_lokasi: null,
        },
      ],
      dataPerkembangan: [],
      dataPemeliharaan: [
        {
          penjarangan_penyulaman: null,
        },
      ],
      dataPemupukan: [],
      dataPestisida: [],
      dataHerbisida: [],
      totalRows: 1,
      currentPage: 1,
      perPage1: 2,
      perPage2: 2,
      perPage3: 1,
      perPage4: 1,
      perPage5: 5,
      perPage6: 5,
      perPage7: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filter2: null,
      filter3: null,
      filter4: null,
      filter5: null,
      filter6: null,
      filter7: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        { key: "nama_kelompok_tani", sortable: true },
        { key: "luas_lahan", sortable: true },
        { key: "ketua", sortable: true },
      ],
      fieldslola: [
        { key: "kode_lokasi", sortable: true },
        { key: "luas", sortable: true },
        { key: "no_plot", sortable: true },
        "Aksi",
      ],
      fieldspm: [
        { key: "kode_lokasi", sortable: true },
        { key: "komoditas", sortable: true },
        { key: "varietas", sortable: true },
        { key: "luas", sortable: true },
      ],
      fieldspb: [
        { key: "kode_lokasi", sortable: true },
        { key: "komoditas", sortable: true },
        { key: "varietas", sortable: true },
        { key: "luas", sortable: true },
      ],
      fieldspemupukan: [
        { key: "pemberian_ke", sortable: true },
        { key: "tgl_pemberian_pupuk", sortable: true },
        { key: "nama_pupuk", sortable: true },
      ],
      fieldspestisida: [
        { key: "pemberian_ke", sortable: true },
        { key: "tgl_pemberian_pestisida", sortable: true },
        { key: "nama_pestisida", sortable: true },
      ],

      fieldsherbisida: [
        { key: "pemberian_ke", sortable: true },
        { key: "tgl_pemberian_herbisida", sortable: true },
        { key: "nama_herbisida", sortable: true },
      ],
      suhu: null,
      curah_hujan: null,
      kecepatan_angin: null,
      arah_angin: null,
      cuaca: null,
      cuaca_img: null,
      form: {
        id: null,
        id_lokasi: null,
        kode_lokasi: null,
        id_penanaman: null,
        waktu_tanam: null,
        tgl_penyiangan: null,
        tgl_pembubunan: null,
        penemuan_hama: null,
        penemuan_penyakit: null,
        p1_tanggal_pemupukan: null,
        p1_nama_pupuk_1: null,
        p1_dosis_pupuk_1: null,
        p1_nama_pupuk_2: null,
        p1_dosis_pupuk_2: null,
        p1_nama_pupuk_3: null,
        p1_dosis_pupuk_3: null,
        penyulaman_tanggal: null,
        penyulaman_jumlah: 0,
        pg_tanggal_herbisida: null,
        pg_nama_herbisida_1: null,
        pg_dosis_herbisida_1: null,
        pg_nama_herbisida_2: null,
        pg_dosis_herbisida_2: null,
        opt_tanggal_fungisida: null,
        opt_nama_fungisida_1: null,
        opt_dosis_fungisida_1: null,
        opt_nama_fungisida_2: null,
        opt_dosis_fungisida_2: null,
        p2_tanggal_pemupukan: null,
        p2_nama_pupuk_1: null,
        p2_dosis_pupuk_1: null,
        p2_nama_pupuk_2: null,
        p2_dosis_pupuk_2: null,
        p2_nama_pupuk_3: null,
        p2_dosis_pupuk_3: null,
        pa_tanggal: null,
        mkp1_tanggal_pemupukan: null,
        mkp1_dosis_pupuk: null,
        pch35_tanggal: null,
        pch35_nama_fungi_insektisida_1: null,
        pch35_dosis_fungi_insektisida_1: null,
        pch35_nama_fungi_insektisida_2: null,
        pch35_dosis_fungi_insektisida_2: null,
        mkp2_tanggal_pemupukan: null,
        mkp2_dosis_pupuk: null,
        pch50_tanggal: null,
        pch50_nama_fungi_insektisida_1: null,
        pch50_dosis_fungi_insektisida_1: null,
        pch50_nama_fungi_insektisida_2: null,
        pch50_dosis_fungi_insektisida_2: null,
        pr_tanggal: null,
        ch_tanggal: null,
        pp_tanggal: null,
        datalokasi: [],
        datapenanaman: [],
      }

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rowstbl1() {
      return this.dataketan.length;
    },
    rowstbl2() {
      return this.datalola.length;
    },
    rowstbl3() {
      return this.datapb.length;
    },
    rowstbl4() {
      return this.datapb.length;
    },
    rowstbl5() {
      return this.dataPemupukan.length;
    },
    rowstbl6() {
      return this.dataPestisida.length;
    },
    rowstbl7() {
      return this.dataHerbisida.length;
    }
  },
  mounted() {
    this.getlokasi();
    this.loadData();
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD, MMM YYYY')
      }
    },
    getDataImage() {
      return "https://picsum.photos/125/125/?image=58";
    },
    loadData() {

      axios
        .get(this.baseapi + "api/dashboard/cuaca", {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        }).then(response => {
          this.suhu = response.data.suhu
          this.curah_hujan = response.data.curah_hujan
          this.kecepatan_angin = response.data.kecepatan_angin
          this.arah_angin = response.data.arah_angin
          this.cuaca = response.data.cuaca
          this.cuaca_img = response.data.cuaca_img
        })
      axios
        .get(this.baseapi + "api/lokasi", {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.datalola = response.data;
          // this.data = response.data;
          var dataMap = [];
          dataMap = response.data;

          //console.log(dataMap[0].nama_unit);
          this.locations = [];
          var i;
          for (i = 0; i < dataMap.length; i++) {
            var lat = dataMap[i].latitude + ",";
            var lon = dataMap[i].longitude;
            var latlon = lat.concat(lon);

            var posisiku = latlon.split(",");
            console.log(latlon);

            this.locations.push({
              id: dataMap[i].id,
              name: dataMap[i].no_plot,

              position: posisiku,
            });
          }

          console.log(response.data.success);
        })
        .catch((response) => {
          console.log(response);
        });

      axios
        .get(this.baseapi + "api/kelompok_tani", {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.dataketan = response.data;
        })
        .catch((response) => {
          console.log(response);
        });

      axios
        .get(this.baseapi + "api/penanaman/perkiraan_panen_mingguan", {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.datapm = response.data;
        })
        .catch((response) => {
          console.log(response);
        });

      axios
        .get(this.baseapi + "api/penanaman/perkiraan_panen_bulanan", {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.datapb = response.data;
        })
        .catch((response) => {
          if (response.response.data.message === 'Unauthorized!') {
            Swal.fire({
              title: "Akses Anda Terputus",
              text: "Silahkan Login Kembali",
              type: "error",
            }).then((r) => {
              console.log(r)
              localStorage.removeItem('user');
              this.$router.push({ path: '/login' })
            });
          }
          console.log(response);
        });
    },


    getDetail(id) {
      console.log(id);
      this.dataDetail = [];
      axios
        .get(this.baseapi + "api/penanaman?filter=id_lokasi,=," + id, {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.dataDetail = response.data;
          this.$bvModal.show('modal-detail');
        })
        .catch((response) => {
          console.log(response);
        });
    },

    getPemeliharaan(id) {
      this.form = {}
      console.log(id);
      this.dataPemeliharaan = [];
      this.dataPemupukan = [];
      this.dataPestisida = [];
      this.dataHerbisida = [];

      axios
        .get(this.baseapi + "api/pemeliharaan?filter=id_lokasi,=," + id, {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.data[0] != null){
            this.form = response.data[0];
            this.$bvModal.show('modal-pemeliharaan')
          }else{
            Swal.fire({
              title: "Informasi",
              text: "Tidak ada data Pemeliharaan",
              type: "error",
            }).then((r) => {
              console.log(r)
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },

    getlokasi() {
      axios.get(this.baseapi + 'api/lokasi', {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      })
        .then(response => {
          this.datalokasi = response.data
        })
        .catch((error) => {
          console.log(error)
          return error
        })
    },

    getpenanaman(id) {
      axios.get(this.baseapi + 'api/penanaman?filter=id_lokasi,=,' + id, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      })
        .then(response => {
          this.datapenanaman = response.data
        })
        .catch((error) => {
          console.log(error)
          return error
        })
    },

    getPerkembangan(id) {
      console.log(id);
      this.series = [];
      this.seriesJD = [];
      axios
        .get(this.baseapi + "api/perkembangan/tinggi_tanaman?id_lokasi=" + id, {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          //this.series = response.data;
          console.log(response);

          var dataSeries = [];
          dataSeries = response.data;
          var i;
          for (i = 0; i < dataSeries.length; i++) {
            //console.log(dataSeries[i].name);
            this.series.push({
              name: dataSeries[i].name,
              data: dataSeries[i].data,
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });

      axios
        .get(this.baseapi + "api/perkembangan/jumlah_daun?id_lokasi=" + id, {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        })
        .then((response) => {
          //this.series = response.data;
          console.log(response);

          var dataSeries = [];
          dataSeries = response.data;
          var i;
          for (i = 0; i < dataSeries.length; i++) {
            //console.log(dataSeries[i].name);
            this.seriesJD.push({
              name: dataSeries[i].name,
              data: dataSeries[i].data,
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },

    clickHandler(event, chartContext, config) {
      console.log("click");
      console.log(event);
      console.log(chartContext);
      var data = config.globals.seriesNames;
      console.log(data[config.dataPointIndex]);

      console.log(config);
      console.log(this.idlokasi);

      this.gambar = null

      axios.post(this.baseapi + "api/perkembangan/ambil_gambar", {
        id_lokasi: this.idlokasi,
        nama: data[config.seriesIndex],
        index: config.dataPointIndex
      },
        {
          headers: {
            Authorization: "Bearer " + this.user.accessToken,
          },
        }).then(response => {
          console.log(response.data);
          this.gambar = response.data.foto
          this.$bvModal.show('modalgambar')
        }).catch(response => {
          console.log(response)
        })


    },

    getDataModal() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Data was submitted successfully", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
  },
};
</script>

<style>
.apexcharts-legend-text {
  color: #ffffff !important;
}
</style>